import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Flex, Link } from 'juhuui';
import React from 'react';

import Address from '../components/address';
import { Button } from '../components/button';
import Hero from '../components/heroAnimated';
import SEO from '../components/seo';
import Subheader from '../components/subheader';
import Layout from '../containers/layout';
import {
  Bright,
  ContainerOuter,
  grid6,
  grid12,
  GridHalfFull,
  MainContent,
  TextLg,
  TextMd,
} from '../styles';
import { getLocalizationObj } from '../utilities/getLocalizationObj';

interface KontaktProps {
  data: GraphQLData;
}

const Page404 = ({ data }: KontaktProps) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const { meta, hero } =
    getLocalizationObj(data.strapi?.contact, language) || {};
  const { title = 'Kontakt', description } = meta || {};
  const { openingHours, phone, email, googleMapsLink } = getLocalizationObj(
    data.strapi?.fact,
    language
  ) || {};

  return (
    <Layout footer={{ onlyNav: true }}>
      <SEO description={description} title={title} />
      <Subheader intro={t('page could not be found')} />
      <MainContent>
        <Bright>
          <ContainerOuter
            className={grid12}
            css={{
              pb: [16, undefined, 0],
              margin: 'auto',
              maxW: 'var(--max-width)',
              px: 'var(--px)',
              pos: 'relative',
              zIndex: 10,
            }}
          >
            <GridHalfFull fw={{ first: true }}>
              <TextLg css={{ m: '0', p: '0' }}>
                <div>{t('book an appointment')}</div>
                <Link
                  css={{
                    display: 'block',
                  }}
                  href={`tel:+49${phone}`}
                >
                  {t('at')} {phone}
                </Link>
                <Link
                  css={{
                    display: 'block',
                  }}
                  href={`mailto: ${email}`}
                >
                  {email}
                </Link>
              </TextLg>
            </GridHalfFull>
            <GridHalfFull>
              <Box className={grid6} css={{ py: ['8', '0'] }}>
                <TextMd
                  css={{
                    column: [
                      '1/span 6',
                      undefined,
                      openingHours ? '1/span 3' : '1/span 6',
                    ],
                  }}
                >
                  <Address />
                  <Flex>
                    <Button
                      css={{
                        c: 'tom.textHighlight',
                        bg: 'inherit',
                        border: '1px solid tom.line',
                        mt: [6],
                        '&:hover': {
                          bg: 'tom.bg',
                        },
                      }}
                      href={googleMapsLink}
                      rel="noopener"
                      target="_blank"
                    >
                      {t('plan your journey')}
                    </Button>
                  </Flex>
                </TextMd>
                {openingHours && (
                  <TextMd
                    css={{
                      column: ['1/span 6', undefined, '4/span 3'],
                      mt: ['6', undefined, '0px'],
                    }}
                  >
                    <div>{t('consultation hour')}</div>
                    <div>{openingHours}</div>
                  </TextMd>
                )}
              </Box>
            </GridHalfFull>
          </ContainerOuter>
        </Bright>
      </MainContent>
      <Hero
        animation={false}
        bottomColor="tom.bgInverted"
        src={hero?.file}
        topColor="tom.bgBright"
      />
    </Layout>
  );
};

export const query = graphql`
  query Page404 {
    strapi {
      fact {
        localizations {
          openingHours
          phone
          email
          googleMapsLink
        }
        openingHours
        phone
        email
        googleMapsLink
      }
      contact {
        localizations {
          subheader
          meta {
            title
            description
          }
          hero {
            url
            file {
              ...Image
            }
          }
        }
        subheader
        meta {
          title
          description
        }
        hero {
          url
          file {
            ...Image
          }
        }
      }
    }
  }
`;

export default Page404;
